import React from "react"
import { SinglePage } from "../components/SinglePage/SinglePage"
import { Layout } from "../components/Layout/Layout"
import { Paragraph } from "../components/blog/common"
import { css } from "@emotion/react"
import { NextSeo } from "next-seo"
import { webUrl } from "../config/web"
import { Col, Row, Space, Typography } from "antd"
import Image from "next/legacy/image"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import Link from "next/link"
import dynamic from "next/dynamic"

const Embed = dynamic(() => import("react-embed"), { ssr: false })

const Organizace = () => {
  const title = "Organizace"
  const description = "Jsme tým programátorů a ekonomů, že programování je zábava a zároveň důležitá dovednost pro jejich vysněné povolání."

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={`${webUrl}/organizace`}
        openGraph={{
          url: `${webUrl}/organizace`,
          title,
          description,
          images: [
            {
              url: `${webUrl}/images/header_laptop.jpg`,
              width: 1920,
              height: 1080,
              alt: "header_laptop"
            }
          ]
        }}
      />

      <Layout>
        <SinglePage title="Organizace">
          <section
            css={css`
              padding: 20px 0 20px 0;
            `}>
            <Space direction={"vertical"} size="large">
              <div>
                <Typography.Title level={2}>Naše poslání</Typography.Title>
                <Paragraph>
                  Dostupné a zábavné programování pro všechny. <br />
                  Boříme bariéry, aby se každý mohl vzdělávat pro svou vysněnou budoucnost.
                </Paragraph>
                <Paragraph>
                  Jsme tým programátorů a ekonomů, kteří chtějí ukázat klukům i holkám, že programování je zábava a zároveň důležitá dovednost pro jejich
                  vysněné povolání.
                </Paragraph>
              </div>

              <Row>
                <Col xl={12} md={14} sm={24} xs={24}>
                  <Embed url="https://youtu.be/RXzEKXjGd58" />
                </Col>
              </Row>

              <Row gutter={[40, 40]} align="middle" justify="center">
                <Col md={16}>
                  <Typography.Title level={3}>Jakub</Typography.Title>
                  <Paragraph>
                    Jakub, náš výkonný ředitel, je ten, kdo dává naší organizaci směr a vizi. Jeho myšlenky na vzdělání a budoucnost jsou tak intenzivní, že
                    někdy se zdá, že i ve spánku hledá nové nápady. Jakub se stará o chod našich akcí, kampaní a projektů a je mistrem ve spojování s dalšími
                    organizacemi. Přístup ke komplexním věcem zvládá s lehkostí a vždy hledá odborníky, kteří nám pomohou lépe porozumět věcem, které
                    potřebujeme k inovacím.
                  </Paragraph>
                </Col>
                <Col
                  md={8}
                  css={css`
                    text-align: center;
                  `}>
                  <ImageRounded width={200} height={200} src="/images/organizace/jakub_2023_800x800.jpeg" alt="jakub_2023_800x800" />
                </Col>
              </Row>

              <Row gutter={[40, 40]} align="middle" justify="center">
                <Col md={16}>
                  <Typography.Title level={3}>
                    Pavel{" "}
                    <Link href="https://www.linkedin.com/in/pavel-proke%C5%A1-3860b282/" title="LinkedIn profil" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        css={css`
                          color: var(--main-color-blue);
                          width: 20px;
                          height: 20px;
                        `}
                      />
                    </Link>
                  </Typography.Title>

                  <Paragraph>
                    Představte si, že bychom neměli Pavla. Ano, naše online svět by možná zkolaboval. Pavel není jen naším CTO, ale také kreativní silou za
                    naším webem, fotografem a vývojářem, který drží všechny digitální aspekty pohromadě. Díky němu máte k dispozici tyto stránky, naše brožury a
                    letáky. Je to také dobrodruh, který spojuje práci softwarového inženýra v Genu s fotografováním společenských událostí a cestováním. Bez
                    Pavla by náš digitální svět nebyl tak barevný a dynamický.
                  </Paragraph>
                </Col>
                <Col
                  md={8}
                  css={css`
                    text-align: center;
                  `}>
                  <ImageRounded width={200} height={200} src="/images/organizace/pavel_2023_800x800.jpeg" alt="pavel_2023_800x800" />
                </Col>
              </Row>

              <Typography.Title level={2}>Další videa</Typography.Title>
              <Row>
                <Col xl={12} md={14} sm={24} xs={24}>
                  <Embed url="https://youtu.be/-WUghiSiJW0" />
                </Col>
              </Row>
            </Space>
          </section>
        </SinglePage>
      </Layout>
    </>
  )
}

export const ImageRounded = styled(Image)`
  border-radius: 50%;
  overflow: hidden;
`

export default Organizace
