import va from "@vercel/analytics"

export interface GaEvent {
  category: string
  action: string
  label?: string
  value?: number
}

export const event = ({ action, category, label, value }: GaEvent) => {
  va.track(action, { category, label, value })

  gtag("event", action, { event_category: category, event_label: label, value })
}

export interface GaPurchaseEvent {
  affiliation: "Website"
  currency: "CZK"
  items: GaPurchaseEventItem[]
  transaction_id: string
  shipping?: number
  value: number
  tax?: number
}

export interface GaPurchaseEventItem {
  item_id: string
  item_name: string
  coupon?: string
  discount?: 0
  affiliation: string
  item_brand: string
  item_category: string
  item_variant: string
  price: number
  currency: "CZK"
  quantity: number
}

export const purchaseEvent = (purchaseEvent: GaPurchaseEvent) => {
  va.track("purchase", { ...purchaseEvent, items: JSON.stringify(purchaseEvent.items) })

  gtag("event", "purchase", purchaseEvent)
}

export interface GaViewEvent {
  currency: "CZK"
  items: GaPurchaseEventItem[]
  value: number
}

export const viewItemEvent = (viewEvent: GaViewEvent) => {
  va.track("view_item", { ...purchaseEvent, items: JSON.stringify(viewEvent.items) })

  gtag("event", "view_item", viewEvent)
}
