import { SubscriberCustomFields } from "./addSubscriberToList"
import axios from "axios"

export const addSubscriberByEmail = async (
  email: string,
  tags: string[],
  customFields?: SubscriberCustomFields,
  resubscribe?: boolean,
  skip_confirmation?: boolean,
  listId?: number
) => {
  return await axios(`/api/ecomail/addSubscribeMember`, {
    method: "post",
    data: {
      email,
      customFields,
      tags,
      listId,
      resubscribe,
      skip_confirmation
    }
  })
}
